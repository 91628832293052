.terms-and-conditions-popup{
    background-color: rgba(156, 158, 149, 0.664);
    height: 110vh;
    width: 100%;
    z-index: 1;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    & :hover{
        background-color: rgba(102, 51, 153, 0.425);
        color: white;
    }
    h1{
        font-size: .5rem;
    }
    div{
        height: 60vh;
        width: 50%;
        background-color: rgb(255, 255, 255);
        padding: 1%;

        button{
            margin-left: 75%;
        }

    }
}

.btn-primary{
    color: black;
}
.open-modal{
    display: flex;
}